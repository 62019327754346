var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Nav from 'react-bootstrap/Nav';
import React from 'react';
import { dayjs } from 'util/nightjs';
import { SmartNavLink } from 'components/links';
import styles from './index.module.scss';
import { useLocation } from 'react-router-dom';
export default function Directory(props) {
    var pathname = useLocation().pathname;
    return (React.createElement(Nav, __assign({ activeKey: pathname, as: "ul", className: styles['directory'] }, props),
        React.createElement(Nav.Item, { as: "li" },
            React.createElement(SmartNavLink, { href: "/" }, "Home")),
        React.createElement(Nav.Item, { as: "li" },
            React.createElement(SmartNavLink, { href: "/watchlist" }, "Watchlist")),
        React.createElement("hr", null),
        React.createElement(Nav.Item, { as: "li" },
            React.createElement(SmartNavLink, { href: "/the-legend-of-the-blue-sea", title: "Posted on ".concat(dayjs('2024-04-01').format('l')) }, "The Legend of the Blue Sea")),
        React.createElement(Nav.Item, { as: "li" },
            React.createElement(SmartNavLink, { href: "/my-secret-romance", title: "Posted on ".concat(dayjs('2024-03-28').format('l')) }, "My Secret Romance")),
        React.createElement(Nav.Item, { as: "li" },
            React.createElement(SmartNavLink, { href: "/where-stars-land", title: "Posted on ".concat(dayjs('2024-03-26').format('l')) }, "Where Stars Land")),
        React.createElement(Nav.Item, { as: "li" },
            React.createElement(SmartNavLink, { href: "/w-tv-series", title: "Posted on ".concat(dayjs('2024-03-22').format('l')) }, "W")),
        React.createElement(Nav.Item, { as: "li" },
            React.createElement(SmartNavLink, { href: "/my-lovey-liar", title: "Posted on ".concat(dayjs('2024-03-18').format('l')) }, "My Lovely Liar")),
        React.createElement(Nav.Item, { as: "li" },
            React.createElement(SmartNavLink, { href: "/alchemy-of-souls", title: "Posted on ".concat(dayjs('2024-03-14').format('l')) }, "Alchemy of Souls")),
        React.createElement(Nav.Item, { as: "li" },
            React.createElement(SmartNavLink, { href: "/full-house", title: "Posted on ".concat(dayjs('2024-02-28').format('l')) }, "Full House")),
        React.createElement(Nav.Item, { as: "li" },
            React.createElement(SmartNavLink, { href: "/hotel-del-luna", title: "Posted on ".concat(dayjs('2024-02-08').format('l')) }, "Hotel del Luna")),
        React.createElement(Nav.Item, { as: "li" },
            React.createElement(SmartNavLink, { href: "/dave-the-diver", title: "Posted on ".concat(dayjs('2023-07-07').format('l')) }, "DAVE THE DIVER")),
        React.createElement(Nav.Item, { as: "li" },
            React.createElement(SmartNavLink, { href: "/lost-judgment", title: "Posted on ".concat(dayjs('2022-10-05').format('l')) }, "Lost Judgment"))));
}
