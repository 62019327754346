import './layout.scss';
import { createRoutesFromElements, Route, Routes } from 'react-router-dom';
import React, { lazy, Suspense, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Directory from 'components/directory';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from 'components/error-page';
import Icon from '@nguyengg/lambdajs/components/icon';
import NotFound from 'components/not-found';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import styles from './layout.module.scss';
var AlchemyOfSoulsReview = lazy(function () {
    return import(
    /* webpackChunkName: "alchemy-of-souls" */
    './alchemy-of-souls');
});
var DaveTheDiverReview = lazy(function () {
    return import(
    /* webpackChunkName: "dave-the-diver" */
    './dave-the-diver');
});
var FullHouseReview = lazy(function () {
    return import(
    /* webpackChunkName: "full-house" */
    './full-house');
});
var HotelDelLunaReview = lazy(function () {
    return import(
    /* webpackChunkName: "hotel-del-luna" */
    './hotel-del-luna');
});
var Home = lazy(function () {
    return import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "homepage" */
    './home');
});
var LostJudgmentReview = lazy(function () {
    return import(
    /* webpackChunkName: "lost-judgment" */
    './lost-judgment');
});
var MyLovelyLiarReview = lazy(function () {
    return import(
    /* webpackChunkName: "my-lovely-liar" */
    './my-lovely-liar');
});
var MySecretRomanceReview = lazy(function () {
    return import(
    /* webpackChunkName: "my-secret-romance" */
    './my-secret-romance');
});
var RecipeForThePerfectRomanticComedy = lazy(function () {
    return import(
    /* webpackChunkName: "recipe-for-the-perfect-romantic-comedy" */
    './recipe-for-the-perfect-romantic-comedy');
});
var TheLegendOfTheBlueSeaReview = lazy(function () {
    return import(
    /* webpackChunkName: "the-legend-of-the-blue-sea" */
    './the-legend-of-the-blue-sea');
});
var Watchlist = lazy(function () {
    return import(
    /* webpackChunkName: "watchlist" */
    './watchlist');
});
var WhereStarsLandReview = lazy(function () {
    return import(
    /* webpackChunkName: "where-stars-land" */
    './where-stars-land');
});
var WTVSeriesReview = lazy(function () {
    return import(
    /* webpackChunkName: "w-tv-series" */
    './w-tv-series');
});
export default function Layout() {
    var _a = useState(false), showDirectory = _a[0], setShowDirectory = _a[1];
    return (React.createElement(Container, { fluid: "md" },
        React.createElement(Row, null,
            React.createElement(Col, { className: styles['directory-column'], xs: 12, md: 3, xl: 2 },
                React.createElement("div", { className: "sticky-top text-end vh-100" },
                    React.createElement(Directory, null))),
            React.createElement(Col, { xs: 12, md: 9, xl: 10 },
                React.createElement(ErrorBoundary, { fallbackRender: ErrorPage },
                    React.createElement(Routes, null, routes)))),
        React.createElement(Button, { className: styles['toggle'], onClick: function () { return setShowDirectory(function (v) { return !v; }); } },
            React.createElement(Icon, { name: "list", width: "1.5rem", height: "1.5rem", fill: "white" })),
        React.createElement(Offcanvas, { placement: "end", scroll: true, show: showDirectory, onHide: function () { return setShowDirectory(false); } },
            React.createElement(Offcanvas.Header, { closeButton: true },
                React.createElement(Offcanvas.Title, null, "Henry Reviews!")),
            React.createElement(Offcanvas.Body, null,
                React.createElement(Directory, { onSelect: function () { return setShowDirectory(false); } })))));
}
var routes = [
    React.createElement(Route, { key: "alchemy-of-souls", index: true, path: "/alchemy-of-souls", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(AlchemyOfSoulsReview, null)) }),
    React.createElement(Route, { key: "dave-the-diver", index: true, path: "/dave-the-diver", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(DaveTheDiverReview, null)) }),
    React.createElement(Route, { key: "full-house", index: true, path: "/full-house", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(FullHouseReview, null)) }),
    React.createElement(Route, { key: "hotel-del-luna", index: true, path: "/hotel-del-luna", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(HotelDelLunaReview, null)) }),
    React.createElement(Route, { key: "home", index: true, path: "/", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(Home, null)) }),
    React.createElement(Route, { key: "lost-judgment", index: true, path: "/lost-judgment", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(LostJudgmentReview, null)) }),
    React.createElement(Route, { key: "my-lovey-liar", index: true, path: "/my-lovey-liar", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(MyLovelyLiarReview, null)) }),
    React.createElement(Route, { key: "my-secret-romance", index: true, path: "/my-secret-romance", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(MySecretRomanceReview, null)) }),
    React.createElement(Route, { key: "recipe-for-the-perfect-romantic-comedy", index: true, path: "/recipe-for-the-perfect-romantic-comedy", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(RecipeForThePerfectRomanticComedy, null)) }),
    React.createElement(Route, { key: "the-legend-of-the-blue-sea", index: true, path: "/the-legend-of-the-blue-sea", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(TheLegendOfTheBlueSeaReview, null)) }),
    React.createElement(Route, { key: "watchlist", index: true, path: "/watchlist", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(Watchlist, null)) }),
    React.createElement(Route, { key: "where-stars-land", index: true, path: "/where-stars-land", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(WhereStarsLandReview, null)) }),
    React.createElement(Route, { key: "w-tv-series", index: true, path: "/w-tv-series", element: React.createElement(Suspense, { fallback: React.createElement(Fallback, null) },
            React.createElement(WTVSeriesReview, null)) }),
    React.createElement(Route, { key: "404", path: "*", element: React.createElement(NotFound, null) }),
];
// used by server to check early if a route exists or not to return an early 404.
export var appRoutes = routes.map(function (r) { return createRoutesFromElements(r); }).reduce(function (a, b) { return a.concat(b); });
function Fallback() {
    return (React.createElement(Container, { as: "main" },
        React.createElement("div", { className: "text-center w-100" },
            React.createElement(Spinner, { animation: "border" }))));
}
